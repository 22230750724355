.eventslist {
    margin-top: 20px;
}

.small-text {
    font-size: 0.9em;
}

.event-location {
    font-weight: bold;
    color: rgb(156, 49, 72);
    text-align: left;
    font-family: "Century Gothic";
}

.event-period {
    cursor: pointer;
    border-radius: 35px;
    padding: 10px 30px;
}

.padded {
    padding: 3px;
}

.event-date {
    line-height: 80%;
    text-space: -1px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: black;
}

.event-date.day {
    font-size: 26px;
}

.event-date.date {
    font-size: 40px;
}

.event-date.month {
    padding-top: 5px;
    font-size: 26px;
    font-weight: normal;
}

a.button, .small-fit-button {
    border-radius: 15px;
    padding: 8px 50px;
    border-width: 0;
    text-decoration: none;
    font-weight: bold;
}

@media (max-width: 576px) {
    .small-hidden {
        display: none;
    }

    a.button, .small-fit-button {
        border-radius: 15px;
        padding: 6px 6px;
        border-width: 0;
        text-decoration: none;
        font-weight: bold;
        font-size: 14px;
    }

}


.fixed-100 {
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 100%;
}

.fixed-125 {
    width: 125px;
    max-width: 125px;
    min-width: 125px;
}

.fixed-150 {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
}

.fixed-50 {
    width: 50px;
    max-width: 50px;
    min-width: 50px;
}

.event-commented {
    font-weight: bold;
    margin-right: 10px;
}

.participant-button {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    margin: auto;
    text-align: center;
    font-size: 18px;
    font-weight: bolder;
}

.participant-button.increment {
    color: white;
    background-color: rgb(156, 49, 72);
    border-width: 0;
    width: 40px;
}

.participant-button.decrement {
    width: 40px;
    color: black;
    background-color: lightgrey;
    border-width: 0;
}

.participant-section {
    margin-top: 10px;
    margin-bottom: 10px;
}

.what-said {
    font-style: italic;
    margin-top: 15px;
    text-align: left;
}

a.no-l {
    text-decoration: underline;
    color: black;
}

.said {
    text-align: center;
    padding: 10px;
}

.social {
    height: 30px;
}

.text-bold {
    font-weight: bold;
}

.control-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url(data:image/svg+xml,%3csvg xmlns= 'http://www.w3.org/2000/svg' viewBox= '0 0 12 12' width= '12' height= '12' fill= 'none' stroke= '%23dc3545' %3e%3ccircle cx= '6' cy= '6' r= '4.5' /%3e%3cpath stroke-linejoin= 'round' d= 'M5.8 3.6h.4L6 6.5z' /%3e%3ccircle cx= '6' cy= '8.2' r= '.6' fill= '%23dc3545' stroke= 'none' /%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.hidden {
    visibility: hidden;
}

.success-registration {
    margin-top: 10px;
    color: white;
    font-size: 12px;
    background: green;
}