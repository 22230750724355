.chat-bubble {
    padding: 20px;
    margin: 20px;
    width: 360px;
    background-color: white;
    border: 2px solid #ccc;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 400px) {
    .chat-bubble {
        width: calc(100% - 20px); /* Réduire la taille à 100% moins les marges */
    }
}

.toto {
    align-items: center;
    display: flex;
}

.padded-left {
    padding-left: 5px;

}

.padded-bottom {
    vertical-align: center;
}