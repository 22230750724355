.spaced {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.promo-28-1 {
    margin-top: 20px;
}

.promo-28-3 {
    margin-top: 20px;
    font-weight: bold;
}

ul {
    display: block;
    margin-block-start: 0em;
}

.promo-container {
    flex-direction: column;
    margin: auto;
}

.md-centered {
    display: flex;
    justify-content: center;
    width: 100%;
}
@media (max-width: 576px) {
    .md-centered {
        display: block;
        justify-content: flex-start;
    }
}

.centered {
    align-items: center;
    text-align: center;
}

.promo-container img {
    width: 95%;
}

.logo-degustation {
    width: 50%;
}

.promo-text {
    font-family: "Century Gothic";
    text-align: left;
    margin-top: 16px;
}

.promo-line1 {
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
    font-size: 42px;
    font-weight: bolder;
    font-family: Georgiab;
    line-height: 0.9em;
    padding-bottom: 10px;
}

.promo-line2 {
    margin-bottom: 8px;
    font-size: 30px;

}

.promo-line3 {
    border-bottom: 1px solid gray;
    width: 80%;
    margin-bottom: 8px;
}

.promo-line4 {
    margin-bottom: 8px;
    font-family: Georgia;
    font-size: 1.05em;
    font-weight: bold;
}

.promo-bold-black {
    font-weight: bold;
    color: black;
}

.promo-red-bold {
    font-weight: bold;
    color: rgb(156, 49, 72);
}

.promo-bold-black-smaller {
    font-weight: bold;
    color: black;
    font-size: 0.8em;
}

.promo-bold-italic {
    font-weight: bold;
    font-style: italic;
    color: black;
    font-size: 0.9em;
}

.small-hr {
    width: 10%;
    margin: 30px auto;
}

.text-bold {
    font-weight: bold;
}
