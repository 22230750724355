.change-language-button {
    background: none;
    border: 1px solid black;
    border-radius: 4px;
    font-weight: bold;
    color: black;
    align-content: center;
    text-align: center;
    padding: 0 10px;
    display: inline-block;
}

.selected-language {
    color: rgb(156, 49, 72);
    border: 1px solid rgb(156, 49, 72);
}