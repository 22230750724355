.app {
    margin: auto;
    max-width: 750px;
    font-family: Georgia;
    text-align: left;
}

@font-face {
    font-family: "century-gothic-bold";
    src: local('century-gothic-bold'),
    url('./fonts/gothicb.ttf');
}

@font-face {
    font-family: "century-gothic";
    src: local('century-gothic'),
    url('./fonts/centurygothic.ttf');
}

@font-face {
    font-family: "Georgia";
    src: local('Georgia'),
    url('./fonts/Georgia.ttf');
}
@font-face {
    font-family: "Georgiab";
    src: local('Georgiab'),
    url('./fonts/georgiab.ttf');
}

.gothic {
    font-family: "century-gothic";
}

h2 {
    font-weight: bold;
    font-family: Georgiab;
}

.font-gothic {
    font-family: "century-gothic";
}
.big-button {
    text-align: center;
    font-size: 1.5em;
    font-family: "century-gothic-bold";
}

.mention-legale {
    margin: auto;
    padding-top: 24px;
    align-content: center;
    text-align: center;
    color: #9ca3af;
    font-size: 0.9em;
}

.text-bold {
    font-weight: bold;
}