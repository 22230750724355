.infos-container {
    background-color: #EEEEEE;
}

.infos-content {
    padding: 40px 0px ;
}

.red-text {
    color: rgb(156, 49, 72);
}

