.periods {
    display: flex;
    width: 100%;
    text-align: center;
}

.period-item {
    margin: 0px 10px;
}

.but-man {
    font-size: 90%;
}

.counter {
    width: 140px;
}

.participant-counter {
    text-align: right;
    padding-bottom: 2px;
    border-width: 0;
    font-size: 1.2em;
    background-color: white;
}

.button-counter {
    border-radius: 15px;
    padding: 2px 10px;
    border-width: 0;
    font-size: 1.2em;
}

.input-arrondi {
    border-radius: 25px;
}
a.button, .button-arrondi {
    border-radius: 15px;
    padding: 8px 50px;
    border-width: 0;
    text-decoration: none;
    font-weight: bold;
}

a.button, .button-validate {
    border-radius: 35px;
    padding: 8px 20px;
    border-width: 0;
    text-decoration: none;
    font-weight: bold;
}

a.button.text-black, text-black {
    font-weight: bold;
    font-size: 1em;
}

.no-back {
    background: white;
}

.button-bordered {
    border: 1px solid lightgrey;
}

.button-grey {
    border: 1px solid lightgrey;
    background-color: lightgrey;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: none;
}

.sl {
    width: 95%;
}

.button-red {
    color: white;
    background-color: rgb(156, 49, 72);
}

.button-disabled {
    background-color: rgba(168, 168, 168, 0.08);
}

.smaller-text {
    font-size: 0.95em;
}

.big-but {
    width: 200px;
}

.align-right {
    text-align: right;
    align-content: flex-end;
}

.validate-email-button {
    background-color: rgb(156, 49, 72);
    margin: auto;
    text-align: center;
    color: white;
    border-radius: 30px;
    border-width: 0;
    width: 80%;
    line-height: 1.5;
    height: 40px;
}
.no-link {
    text-decoration: none;
    padding: 2px 0;
}

a.hover:hover {
    text-decoration: underline;
}

.logo-centered {
    position: relative;
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.language-button-pos {
    position: absolute;
    padding: 10px;
    right: 1%;
    top: 5%;
    z-index: 2;
}

.side-content-center {
    position: relative;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}